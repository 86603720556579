html {
    box-sizing: border-box;
    width: 100%;
    -webkit-scroll-behavior: smooth;
    -moz-scroll-behavior: smooth;
    -ms-scroll-behavior: smooth;
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'SF Mono';
    src: url("./SFMono-Regular-8799e6387338d58f2f137df821c86eb4.woff2") format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

/*!sc*/


*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: var(--navy);
    color: var(--slate);
    font-family: var(--font-sans);
    font-size: var(--fz-xl);
    line-height: 1.3;
}

:root {
    --dark-navy: #020c1b;
    --navy: #0a192f;
    --light-navy: #112240;
    --lightest-navy: #233554;
    --navy-shadow: rgba(2, 12, 27, 0.7);
    --dark-slate: #495670;
    --slate: #8892b0;
    --light-slate: #a8b2d1;
    --lightest-slate: #ccd6f6;
    --white: #e6f1ff;
    --green: #64ffda;
    --green-tint: rgba(100, 255, 218, 0.1);
    --pink: #f57dff;
    --blue: #57cbff;
    --font-sans: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
    --font-mono: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
    --fz-xxs: 12px;
    --fz-xs: 13px;
    --fz-sm: 14px;
    --fz-md: 16px;
    --fz-lg: 18px;
    --fz-xl: 20px;
    --fz-xxl: 22px;
    --fz-heading: 32px;
    --border-radius: 4px;
    --nav-height: 100px;
    --nav-scroll-height: 70px;
    --tab-height: 42px;
    --tab-width: 120px;
    --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
    --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    --hamburger-width: 30px;
    --ham-before: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
    --ham-before-active: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
    --ham-after: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    --ham-after-active: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
}

.header {
    background-color: var(--navy);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100px;
    box-shadow: 0 10px 30px -10px var(--navy-shadow);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: sticky;
    z-index: 100;
    top: 0;
}


.logo {
    color: var(--green);
    font-size: 2em;
}


.nav-header {
    display: flex;
}

.nav-header a {
    text-decoration: none;
    color: var(--white);
    font-size: var(--fz-sm);
}

.nav-header a:hover {
    color: var(--green);
}

.nav-header div {
    margin-left: 30px;
}

.nav-header-num {
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-sm);
    line-height: 1.1;
}

.content {
    background-color: var(--navy);
    width: 100%;
    height: 100vh;
    display: flex;
    margin: auto auto;
    justify-content: center;
    position: relative;
    top: 100px;
}

.content-main {
    margin-top: 50px;
    width: 80%;
}

.secondary {
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    line-height: 1.1;
}

.primary-light {
    color: var(--lightest-slate);
    font-family: var(--font-mono);
    font-weight: 600;
}

.name {
    font-size: clamp(40px, 6vw, 80px);
    line-height: 1.1;
    margin-top: 10px;
}

.primary {
    color: var(--slate);
    font-family: var(--font-mono);
    font-weight: 400;
}

.mission {
    font-size: clamp(40px, 6vw, 80px);
}

.home .intro {
    margin-top: 20px;
    font-size: 1em;
    width: 70%;
    line-height: 1.5;
    color: var(--slate);
}

.title-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title-header .number {
    font-size: clamp(var(--fz-md), 3vw, 80px);
    margin-right: 10px;
}

.title-header .name {
    font-size: clamp(2vw, 3vw, 80px);
    font-weight: 600;
    color: var(--white);
    display: flex;
    position: relative;
    top: -5px;
}

.title-header .number .title-header hr {
    width: 50px;
}

.title-header .name::after {
    content: "";
    background-color: var(--lightest-navy);
    height: 1px;
    width: 30vw;
    display: block;
    position: relative;
    top: 20px;
    margin-left: 20px;
}

.section {
    margin-top: 200px;
}

.about-section {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

@media (max-width:450px) {
    .title-header .name {
        font-size: clamp(3vw, 3.5vw, 80px);
    }

    .title-header .name::after {
        top: 8px;
    }

    .nav-header {
        display: grid;
        grid-template-columns: auto auto;
    }

}

.skills {
    display: flex;
}


.about-details {
    display: block;
    color: var(--slate);
    font-family: var(--font-sans);
    -webkit-font-smoothing: antialiased;
    font-size: var(--fz-lg);
    line-height: 1.3;
    border: 3px solid var(--navy);
    border-radius: 10px;
    width: 60%;
}

.about-details>p {
    margin-top: 30px;
}

@media(max-width:600px) {
    .about-section {
        display: flex;
        flex-direction: column;
    }

    .about-details {
        width: 100%
    }

    .logo {
        font-size: 1em;
    }
}

.about-skillsets {
    margin-right: 100px;
    font-family: var(--font-mono);
}

.about-skillsets ul {
    list-style: none;
    margin-top: 20px;
}

.about-skillsets li::before {
    content: ">>";
    color: var(--green);
    font-size: var(--fz-sm);
    margin-right: 10px;
}

.about-skillsets li {
    font-size: var(--fz-sm);
    margin-top: 8px;
}

.about-picture {
    width: 300px;
    height: 300px;
    margin-top: 30px;
    border-left: 2px solid var(--green);
    border-top: 2px solid var(--green);
    border-radius: 5px;
    padding: 10px;

}

.about-image {
    width: 300px;
    height: 300px;
    opacity: 0.6;
    object-fit: cover;
    filter: grayscale(100%) contrast(1);
}

.about-image:hover {
    opacity: 1;
}

.experience {
    display: flex;
    margin-top: 50px;
}


.experience .experience-companyul {
    list-style: none;
    width: fit-content;
}

.experience .experience-companyul li {
    padding: 10px;
    font-size: var(--fz-md);
    cursor: pointer;
    border-left: 2px solid var(--lightest-navy);
}

.experience .experience-companyul li:hover,
.experience .experience-companyul li:focus,
.experience .experience-companyul li:active {
    color: var(--green);
    background-color: var(--lightest-navy);
}

.experience .experience-companyul .experience-selected {
    color: var(--green);
    border-left: 2px solid var(--green);
    background-color: var(--lightest-navy);
}

@media (max-width:450px) {
    .experience {
        flex-direction: column;
    }

    .experience .experience-companyul {
        display: flex;
        margin-bottom: 20px;
    }

    .experience .experience-companyul .experience-selected {
        color: var(--green);
        border-left: 0;
        border-top: 2px solid var(--green);
        background-color: var(--lightest-navy);
    }

    .experience .experience-companyul li {
        border-top: 2px solid var(--lightest-navy);
        border-left: 0;
    }
}

.experience .experience-content .experience-roledetail {
    width: 80%;
    margin-left: 5vw;
    font-size: clamp(--fz-sm, 1vw, --fz-md);
}

.experience-role {
    color: var(--white);
    font-size: clamp(2vw, 2vw, 2vw);
    margin-bottom: 10px;
}

.experience-role a {
    color: var(--green);
}

.experience-duration {
    color: var(--white);
    color: var(--lightest-slate);
    margin-bottom: 10px;
}

.experience-role span {
    color: var(--green)
}

.experience-jd li {
    padding: 1px;
    list-style: none;
    margin-top: 15px;
    font-size: clamp(var(--fz-lg), 1vw, var(--fz-xl))
}

.experience-jd li::before {
    content: ">>";
    color: var(--green);
    margin-right: 10px;
}

.experiencejd-ul-span {
    color: var(--green)
}

.projects {
    display: flex;
    flex-direction: column;
}


.projects .project {
    margin-top: 50px;
    width: 60vw;
    height: 30vh;
    background-color: var(--light-navy);
    border-radius: 3px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}



@media (max-width:730px) {
    .projects .project {
        height: 35vh;
    }
}

@media (max-width:550px) {
    .projects .project {
        height: 38vh;
    }

    .logo {
        font-size: 1em;
    }
}

@media (max-width:400px) {
    .projects .project {
        height: 45vh;
    }
}

.projects .project-name {
    margin-bottom: 20px;
    text-align: left;
    color: var(--white);
    font-size: clamp(var(--fz-md), 1.6vw, 80px);
}

.projects .project:hover {
    cursor: pointer;
}

.project-name:hover {
    color: var(--green)
}

.project .project-description {
    font-size: var(--fz-md);
}

.project-tools-ul {
    list-style: none;
    display: flex;
    margin-top: 10px;
}


.project-tools-ul li {
    margin-right: 10px;
    color: var(--slate);
    font-size: var(--fz-xxs);
    font-family: var(--font-mono);
    line-height: 1.75;
}

.contact {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 100px;
}

.contact .contact-heading {
    font-size: clamp(30px, 4vw, 60px);
    font-weight: 600;
    color: var(--white);

}

.contact .contact-description {
    color: var(--slate);
    margin-bottom: 100px;
}

.contact a {
    border: 2px solid var(--green);
    padding: 10px;
    text-decoration: none;
    color: var(--green);
    font-family: var(--font-mono);
    margin-top: 50px;
    margin-right: 10px;
    border-radius: 3px;
}

.section:last-child {
    padding-bottom: 250px;
}

.contact-email {
    margin-top: 100px;
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
}